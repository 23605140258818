import React from "react"
import "../style/footer.scss"

const Footer = () => {
  return (
    <footer>
      <p className="copyright">© Ezprop Plt</p>
      <div className="contact">
        <p className="name">Ya Teng</p>
        <p className="phone">+6010 368 4226</p>
      </div>
    </footer>
  )
}

export default Footer
