import React from 'react'

const Hero = ({children, bgColor}) => {
  return (
    <section className="hero">
      {children}
    </section>
  )
}

export default Hero;