import { Link } from "gatsby"
import React from "react"

import "../style/header.scss"

const Logo = () => {
  return (
    <svg viewBox="0 0 94 94">
      <defs>
        <filter id="a" width="167.1%" height="167.1%" x="-33.6%" y="-33.6%" filterUnits="objectBoundingBox">
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4.5"/>
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.313519022 0"/>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" filter="url(#a)" transform="translate(9 5)">
        <rect width="76" height="76" fill="#1A1A1B" rx="9"/>
        <path id="svg-content" d="M61.3 66L15.7 30.4V66H9V10h59v56h-6.7zM15.7 16.8v5l19.4 15.4V16.8H15.7zm26.2 0v26l19.4 14.7V16.8H41.9z"/>
      </g>
    </svg>
  )
}

const Header = () => (
  <header>
    <Link className="logo" to="/" activeClassName="active">
      <Logo/>
      <span>EZProps | Best Rental Service around Sunway</span>
    </Link> 
    <ul>
      <li><Link to="/location" activeClassName="active">Location</Link></li>
      <li><Link to="/room" activeClassName="active">Rooms</Link></li>
      <li><Link to="/contact" activeClassName="active">Contact</Link></li>
    </ul>
  </header>
)

export default Header
